import { Injectable, signal, WritableSignal } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Organization } from '../../state/models/organization';
import { UrlService } from './url.service';
import { AmplitudeService } from './amplitude.service';
import { Cycles } from '../../state/models/cycles';
import { MenuLayoutService } from './menu-layout.service';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {
  private cycles: WritableSignal<Cycles> = signal(null);

  marOrgWhitelabel: Organization | undefined;
  isAdmin: boolean;
  isManyOrgs: boolean;

  constructor(
    private menuLayoutService: MenuLayoutService,
    private amplitudeService: AmplitudeService,
    private urlService: UrlService) {
  }

  get hasAdmin(): boolean {
    return this.isAdmin || false;
  }

  get hasManyOrgs(): boolean {
    return this.isManyOrgs || false;
  }


  get cycle(): Cycles {
    return this.cycles();
  }

  setInfoPermissions(extras?: { hasAdmin?: boolean, hasManyOrgs?: boolean }) {
    const { hasAdmin = false, hasManyOrgs = false } = extras || {};
    this.isManyOrgs = hasManyOrgs;
    this.isAdmin = hasAdmin;
  }

  clearOrganization() {
    this.marOrgWhitelabel = undefined;
    this.cycles.set(undefined);
  }

  setOrganization(organization?: Organization): boolean {
    if (organization) {
      this.marOrgWhitelabel = organization;
      this.getAmplitudeInfo(organization);
    } else {
      this.marOrgWhitelabel = DEFAULT_ORGANIZATION;
    }
    this.amplitudeService.setIdentify();
    return true;
  }

  getOrganization(): Organization | undefined {
    return this.marOrgWhitelabel || DEFAULT_ORGANIZATION;
  }

  getAmplitudeInfo(organization: Organization) {
    const { parent, type, id, name } = organization;
    this.amplitudeService.addEdentifyEvent('org_id', id?.toString());
    this.amplitudeService.addEdentifyEvent('org_type', type);
    this.amplitudeService.addEdentifyEvent('org_name', name);
    if (parent) {
      const { type: pType, id: pId, name: pName } = parent;
      this.amplitudeService.addEdentifyEvent('parent_org_id', pId?.toString());
      this.amplitudeService.addEdentifyEvent('parent_org_type', pType);
      this.amplitudeService.addEdentifyEvent('parent_org_name', pName);
    }
  }

  getDefaultUrl(): string {
    const organization = this.getOrganization();
    return this.urlService.organizationsBase(organization.id);
  }

  private isDevEnvironment(): boolean {
    return environment.env === 'dev';
  }
}

const DEFAULT_ORGANIZATION: Organization = {
  nameUrlSafe: 'mar',
  name: 'Gestor | Mar Saúde',
  logo: 'https://mar-static.s3.us-east-2.amazonaws.com/organization-logo/mar.png',
  favicon: 'https://mar-static.s3.us-east-2.amazonaws.com/organization-favicon/mar.ico'
};
