import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { Menu, MenuLayoutService } from '../../../services/menu-layout.service';
import { JwtAuthService } from '../../../services/auth/jwt-auth.service';
import { Person } from '../../../../state/models/person';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import { filter, tap } from 'rxjs/operators';
import { OrganizationService } from '../../../services/organization.service';
import { AutoUnsubscribe, CombineSubscriptions } from '../../../decorators/auto-unsubscribe.decorator';
import { noop, Unsubscribable } from 'rxjs';
import { AmplitudeService } from '../../../services/amplitude.service';
import { Organization } from '../../../../state/models/organization';
import { RouterParamsService } from '../../../services/router-params.service';
import { ViewportScroller } from '@angular/common';
import { OrganizationEntityService } from '../../../../state/entity-services/organization-entity.service';

@Component({
  selector: 'menu-layout',
  templateUrl: './menu-layout.component.html',
  styles: `
    .example-spacer {
      flex: 1 1 auto;
    }

    .toolbar-item-spacer {
      flex: 1 1 auto;
    }

    .app-container {

      .body-container {
        height: 100vh;
        overflow-y: auto;

        @supports (height: 100dvh) {
          height: 100dvh;
        }
      }
    }

    .app-sidenav-content {
      height: calc(100vh - 64px);
      @media screen and (max-width: 600px) {
        height: calc(100vh - 56px);
      }

      &.full {
        height: 100vh;
      }

      @supports (height: 100dvh) {
        height: calc(100dvh - 64px);
        @media screen and (max-width: 600px) {
          height: calc(100dvh - 56px);
        }
        &.full {
          height: 100dvh;
        }
      }
    }

    mat-list-item {
      .mat-mdc-list-item-unscoped-content {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  `
})
@AutoUnsubscribe()
export class MenuLayoutComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {
  toolbarNav: boolean;
  user: Person;
  @ViewChild('sidenav') drawer: MatSidenav;
  organization: Organization;
  isExpanded: boolean;
  title: string;
  menuBanned: string[] = [];
  menu: Menu[] = [];
  menuFooter: Menu[] = [];
  params: any = {};
  @CombineSubscriptions()
  private subscriptions: Unsubscribable;

  openMenuMode: 'over' | 'push' | 'side' = 'side';

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.setModeOpenMenu();
  }


  @ViewChild('container') container: ElementRef;

  constructor(
    private cdr: ChangeDetectorRef,
    private router: Router,
    private routerParamsService: RouterParamsService,
    private jwtAuthService: JwtAuthService,
    private menuLayoutService: MenuLayoutService,
    private organizationService: OrganizationService,
    private amplitudeService: AmplitudeService,
    private viewportScroller: ViewportScroller,
    private organizationEntityService: OrganizationEntityService,
  ) {
    this.setModeOpenMenu();
    this.organization = organizationService.getOrganization();

    this.subscriptions = this.jwtAuthService.user$.asObservable().subscribe({
      next: () => {
        this.user = jwtAuthService.getUser();
        this.organization = organizationService.getOrganization();
      }
    });

    this.subscriptions = this.menuLayoutService.layoutConf$.subscribe(config => {
      this.title = config.titlePage;
      this.toolbarNav = config.toolbarNav;
      this.menu = config.menu || [];
      this.menuBanned = config.menuBanned || [];
      this.menuFooter = config.menuFooter || [];
      this.user = jwtAuthService.getUser();
      this.updateMenu();
    });

    this.subscriptions = this.organizationEntityService.getCurrent().subscribe(organization => {
      this.organization = organization;
      this.updateMenu();
    });

    this.routerParamsService.params.pipe(
      tap(params => {
        this.params = params;
        this.updateMenu();
      })
    ).subscribe(noop);

    this.subscriptions = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(routeChange => {
        if (this.drawer) {
          this.drawer?.close().then();
        }
        setTimeout(() => {
          this.setToTop();
        }, 0);

        this.menuLayoutService.updateLayoutConfig();
      });
  }

  ngOnInit() {
    this.updateMenu();
  }

  ngOnDestroy() {
  }

  ngAfterViewInit() {
  }

  setToTop() {
    this.viewportScroller.scrollToPosition([0, 0]);
    if (this.container) {
      this.container.nativeElement.scrollTop = 0;
    }
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  logout() {
    this.jwtAuthService.logout();
  }

  setModeOpenMenu(): void {
    window.innerWidth <= 600 ? this.openMenuMode = 'over' : this.openMenuMode = 'side';
  }

  updateMenu() {
    /* remove menu by ban */
    this.menuBanned.forEach(mb => {
      this.menu = this.menu.filter(m => mb !== m.path);
      this.menuFooter = this.menuFooter.filter(m => mb !== m.path);
    });

    this.menu = this.menu.filter(m => !!m.path).map(m => {
      const { idRequired } = m;
      return {
        ...m,
        path: this.searchIntoPath(m.path, this.params),
        showPath: idRequired ? !!this.params[idRequired] : true
      };
    });

    this.menuFooter = this.menuFooter.filter(m => !!m.path).map(m => {
      const { idRequired } = m;
      return {
        ...m,
        path: this.searchIntoPath(m.path, this.params),
        showPath: idRequired ? !!this.params[idRequired] : true
      };
    });
    this.filterByOrganizationType();
    this.setMenuActive();
    this.checkExcludeMenuByParam();
    this.checkCustomer();
  }

  checkExcludeMenuByParam() {
    const processMenu = (menu) =>
      menu
        .filter((m) => !!m.path) // Filtrar itens com path válido
        .map((m) => {
          const { excludeByParam, showPath } = m;

          // Verifica se o excludeByParam está presente nos parâmetros (ou suporta múltiplos valores separados por vírgula)
          const shouldExclude = excludeByParam
            ? excludeByParam
              .split(',')
              .some((param) => !!this.params[param.trim()])
            : false;

          return {
            ...m,
            showPath: shouldExclude ? false : showPath, // Atualiza showPath com base na exclusão
          };
        });

    // Processa o menu e o menuFooter usando a lógica genérica
    this.menu = processMenu(this.menu);
    this.menuFooter = processMenu(this.menuFooter);
  }

  setMenuActive() {
    this.menu = this.menu.map(m => {
      if (m.activeType === 'PARTIAL') {
        return { ...m, active: location.pathname.indexOf(m.path) > -1 };
      }
      return { ...m, active: m.path === location.pathname };
    });
    this.menuFooter = this.menuFooter.map(m => {
      if (m.activeType === 'PARTIAL') {
        return { ...m, active: location.pathname.indexOf(m.path) > -1 };
      }
      return { ...m, active: m.path === location.pathname };
    });
  }

  filterByOrganizationType() {
    this.menu = this.menu.filter(m => {
      return !m.acl || m.acl.includes(this.organization.type);
    });
    this.menuFooter = this.menuFooter.filter(m => {
      return !m.acl || m.acl.includes(this.organization.type);
    });
  }

  searchIntoPath(path: string, params: unknown) {
    if (!params) {
      return path;
    }
    let newString = path;
    Object.keys(params).forEach(key => {
      newString = newString.replace(new RegExp(key, 'g'), params[key]);
    });
    return newString;
  }

  openWhatsApp() {
    this.amplitudeService.sendEvent('contact_support_clicked', {
      stepName: location.pathname,
    });
    const msg = 'Olá, estou com dúvidas no processo preventivo e gostaria de ajuda.\n';
    window.open(`https://wa.me/5511912743152?text=${ encodeURIComponent(msg) }`, '_blank');
  }

  toggle() {
    this.drawer.toggle();
  }

  private checkCustomer() {
    this.menu = this.menu.filter(m => {
      if (m.hasOwnProperty('checkParent')) {
        return !this.organization?.parent;
      }
      return m;
    });
    this.menuFooter = this.menuFooter.filter(m => {
      if (m.hasOwnProperty('checkParent')) {
        return !this.organization?.parent;
      }
      return m;
    });
  }
}
