import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { JwtAuthService } from '../services/auth/jwt-auth.service';
import { Observable } from 'rxjs';
import { AclService } from '../services/acl.service';
import { map } from 'rxjs/operators';
import { PersonEntityService } from '../../state/entity-services/person-entity.service';

@Injectable()
export class AuthGuard {
  constructor(
    private personEntityService: PersonEntityService,
    private aclService: AclService, private router: Router,
    private jwtAuth: JwtAuthService) {
  }

  canActivate(
    route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    if (this.jwtAuth.isLoggedIn) {
      return this.aclService.getRoles(this.jwtAuth.getUser().id).pipe(
        map(response => {
          this.personEntityService.setAuthStatement(response.authStatements);
          return true;
        }),
      );
    } else {
      this.router.navigate(['/sessions/login'], {
        queryParams: {
          return: state.url,
        },
      });
      return false;
    }
  }
}
