import { NgModule } from '@angular/core';
import { ShowOnSizeDirective } from './showOnSize.directive';
import { FlexChildDirective, FlexDirective } from './flex.directive';
import { BottomSheetDraggableDirective } from './bottom-sheet-draggable.directive';
import { ApplyClassOnSizeDirective } from './apply-class-on-size.directive';
import { PermissionDirective } from './permission.directive';

@NgModule({
  declarations: [
    PermissionDirective,
    ApplyClassOnSizeDirective,
    ShowOnSizeDirective,
    FlexDirective,
    FlexChildDirective,
    BottomSheetDraggableDirective
  ],
  exports: [
    PermissionDirective,
    ApplyClassOnSizeDirective,
    ShowOnSizeDirective,
    FlexDirective,
    FlexChildDirective,
    BottomSheetDraggableDirective,
  ],
})
export class SharedDirectivesModule {
}
