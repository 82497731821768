import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { PersonMessagesEntityService } from '../../../../state/entity-services/person-messages-entity.service';
import { UtilsService } from '../../../services/utils.service';
import { ButtonOptions, ContentBlock, PersonMessage } from '../../../../state/models/person-messages';
import { switchMap, tap, Unsubscribable } from 'rxjs';
import { AutoUnsubscribe, CombineSubscriptions } from '../../../decorators/auto-unsubscribe.decorator';
import { marked } from 'marked';
import { JwtAuthService } from '../../../services/auth/jwt-auth.service';
import { Person } from '../../../../state/models/person';
import { filter, map } from 'rxjs/operators';
import { MariaDialogsService } from '../../services/maria-dialogs.service';
import { ConfirmService } from '../../../services/app-confirm/confirm.service';
import { MariaUtilsService } from '../../services/maria-utils.service';
import { PersonEntityService } from '../../../../state/entity-services/person-entity.service';
import { CrmPersons } from '../../../../state/models/crm-persons';

@Component({
  selector: 'maria-chat-dialog',
  templateUrl: './maria-chat-dialog.component.html',
  styleUrls: ['./maria-chat-dialog.component.scss']
})
@AutoUnsubscribe()
export class MariaChatDialogComponent implements OnDestroy, AfterViewInit {
  messageControl = new FormControl(null);
  messages: MessageChat[] = [];
  buttonOptions: ButtonOptions[] = [];
  loading = false;
  loadingFirstInteraction = false;
  @CombineSubscriptions()
  private subscriptions: Unsubscribable;
  interaction = false;

  person: Person;
  crmPerson: CrmPersons;

  params: any = {};

  @ViewChild('scrollContainer') private scrollContainer!: ElementRef;
  @ViewChild('message') private input!: ElementRef;

  constructor(
    private personEntityService: PersonEntityService,
    private mariaUtilsService: MariaUtilsService,
    private mariaDialogsService: MariaDialogsService,
    private utilsService: UtilsService,
    private personMessageEntityService: PersonMessagesEntityService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.person = {id: data?.personIds}
    this.params = data;
    this.personMessageEntityService.getMessagesApi(this.params).subscribe({
      next: data => {
        this.formatMessage(data);
      },
      complete: () => {
        this.loadingFirstInteraction = true;
      }
    });

    this.subscriptions = this.personEntityService.crmPersons(this.person.id)
      .pipe(tap(crmPerson => {
        this.crmPerson = crmPerson;
      }))
      .subscribe({
        error: error => {
          this.utilsService.setErrorToast(error);
        }
      });
  }

  ngOnDestroy() {
    this.mariaUtilsService.setUserFirstChatOpen();
  }

  ngAfterViewInit(): void {

  }

  private scrollToBottom(): void {
    setTimeout(() => {
      try {
        this.scrollContainer.nativeElement.scrollTop = this.scrollContainer.nativeElement.scrollHeight;
      } catch (err) {
        console.error('Erro ao tentar rolar para o final:', err);
      }
    }, 10);
  }

  formatMessage(personMessages: PersonMessage[], updateMessages: boolean = false): void {

    personMessages.reverse().forEach(pm => {
      if (!updateMessages) {
        this.messages = this.messages.filter(message => message.id !== 0);
      }
      const existingMessage = this.messages.find(m => m.id === pm.id);
      if (updateMessages && existingMessage) {
        existingMessage.rating = pm.adminRating;
        existingMessage.adminCorrectedContent = pm.adminCorrectedContent?.replace(/\r\n|\r|\n/g, '<br>');
      } else if (!existingMessage) {
        this.messages.push({
          id: pm.id,
          type: pm.source.toLocaleLowerCase(),
          chat: this.getMessageByType(pm.contentBlocks, pm.source),
          rating: pm.adminRating,
          adminCorrectedContent: pm.adminCorrectedContent?.replace(/\r\n|\r|\n/g, '<br>')
        });
      }
    });
    const [personMessage] = personMessages;
    const { contentBlocks } = personMessage;
    const [contentBlock] = contentBlocks;

    if (personMessage.source === 'SYSTEM' && contentBlock.type !== 'BUTTON_OPTIONS') {
      this.buttonOptions = [];
    }

    if (!updateMessages) {
      this.scrollToBottom();
    }
  }

  getMessageByType(contentBlocks: ContentBlock[], source: PersonMessage['source']): any[] {
    let messages: MessageByType[] = [];
    contentBlocks.forEach(contentBlock => {
      if (contentBlock.type === 'PLAIN_TEXT') {
        messages.push({ type: 'text', message: contentBlock.plainText.plainText });
      }
      if (contentBlock.type === 'MARKDOWN') {
        messages.push({ type: 'text', message: marked(contentBlock.markdown.markdown) });
      }
      if (contentBlock.type === 'GO_TO') {
        messages.push({ type: 'button', button: contentBlock.goTo.destination });
      }
      if (contentBlock.type === 'BUTTON_OPTIONS') {
        const { buttonOptions = [] } = contentBlock;
        if (source === 'SYSTEM') {
          this.buttonOptions = buttonOptions;
        } else if (source === 'USER') {
          const [buttonOption] = buttonOptions;
          const { plainText } = buttonOption;
          messages.push({ type: 'text', message: plainText });
        }
      }
    });
    return messages;
  }

  sendMessage(buttonOption?: ButtonOptions) {
    this.loading = true;
    this.scrollToBottom();
    this.messageControl.disable({ onlySelf: true });
    this.interaction = true;
    this.subscriptions = this.personMessageEntityService.sendMessage({
      contentBlocks: [
        buttonOption ? this.setButtonOption(buttonOption) : this.setPlainText(),
      ]
    })
      .pipe(
        switchMap(msg => {
          return this.personMessageEntityService.getMessagesApi().pipe(
            tap(response => {
              this.scrollToBottom();
              this.formatMessage(response);
            })
          );
        })
      )
      .subscribe({
        complete: () => {
          this.messageControl.enable({ onlySelf: true });
          this.messageControl.reset();
          this.input.nativeElement.focus();
          this.loading = false;
        },
        error: err => {
          this.loading = false;
          this.utilsService.setErrorToast(err);
        }
      });
  }

  setButtonOption(buttonOption: ButtonOptions): ContentBlock {
    const { plainText } = buttonOption;
    this.messages.push({
      id: 0,
      type: 'user',
      chat: [{ type: 'text', message: plainText }],
    });
    return {
      type: 'BUTTON_OPTIONS',
      buttonOptions: [buttonOption]
    };
  }

  setPlainText(): ContentBlock {
    const value = this.messageControl.value;
    this.messages.push({
      id: 0,
      type: 'user',
      chat: [{ type: 'text', message: value }],
    });
    return {
      type: 'PLAIN_TEXT',
      plainText: {
        plainText: value,
      }
    };
  }

  sendRatingMessage(adminRating: PersonMessage['adminRating'], message: MessageChat) {
    if (adminRating === 'BAD') {
      this.mariaDialogsService.openPersonMessageRatingUserBad(message).pipe(
        tap((adminCorrectedContent) => {
          if (adminCorrectedContent) {
            this.sendEvent(adminRating, message, adminCorrectedContent);
          }
        }),
      ).subscribe({
        error: err => {
          this.utilsService.setErrorToast(err);
        }
      });
      return;
    }
    this.sendEvent(adminRating, message, null);
  }

  sendEvent(adminRating: PersonMessage['adminRating'], message: MessageChat, adminCorrectedContent?: string) {
    this.personMessageEntityService.ratingMessage({
      id: message.id,
      adminRating,
      adminCorrectedContent,
    }, this.params)
      .pipe(
        map(response => {
          this.formatMessage(response, true);
        })
      )
      .subscribe({
        error: err => {
          this.utilsService.setErrorToast(err);
        }
      });
  }
}

export interface MessageChat {
  id: number,
  chat: MessageByType[],
  type: string;
  rating?: string;
  adminCorrectedContent?: string;
}

export interface MessageByType {
  message?: any;
  type: string,
  button?: string
  buttons?: { button: string }[];
}
