<h1 mat-dialog-title class="title-nav">
  <button mat-icon-button mat-dialog-close>
    <mat-icon>arrow_back</mat-icon>
  </button>
  <img src="/assets/images/maria/star.svg" alt="Star Ia"/>
  <div [innerHTML]="'Assistente de Saúde'"></div>
</h1>
<div mat-dialog-content class="content" #scrollContainer>
  @if (messages.length > 0 && loadingFirstInteraction) {
    <div class="chat-messages">
      <ng-container *ngFor="let message of messages; let last = last; index as i">
        <div class="bubble-message" [class]="message.type">
          <div>
            @for (chat of message.chat; track chat) {
              @if (chat.type === 'text') {
                <span class="text"
                      [innerHTML]="chat.message"></span>
              } @else {
                <button mat-raised-button color="accent">
                  {{ chat.button | stringFormat }}
                </button>
              }
            }
          </div>
        </div>
        <div class="rating" *ngIf="message.type === 'system'">
          <span class="text">Avalie essa mensagem</span>
          <div>
            <button mat-button (click)="sendRatingMessage('GOOD', message)">
              <mat-icon [class.material-icons-full]="message?.rating === 'GOOD'">thumb_up</mat-icon>
              <span>Boa</span>
            </button>
            <button mat-button (click)="sendRatingMessage('BAD', message)">
              <mat-icon [class.material-icons-full]="message?.rating === 'BAD'">thumb_down</mat-icon>
              <span>Ruim</span>
            </button>
          </div>
          <mat-card *ngIf="message.adminCorrectedContent">
            <mat-card-content>
              <p [innerHTML]="message.adminCorrectedContent"></p>
            </mat-card-content>
          </mat-card>
        </div>
      </ng-container>
    </div>
  } @else if (messages.length === 0 && loadingFirstInteraction) {
    <div class="chat-messages empty-state">
      <div class="center">
        <img src="/assets/images/maria/star-big.svg" alt="Star Ia Olá"/>
        <h2>Olá, {{ person?.personProperties?.name }}. Sou sua assistente de Saúde. Você tem alguma dúvida?</h2>
      </div>
    </div>
  }

  <div class="button-options" *ngIf="buttonOptions.length">
    <mat-chip-set aria-label="Opções">
      @for (item of buttonOptions; track item) {
        <mat-chip (click)="sendMessage(item)">{{ item.shortText }}</mat-chip>
      }
    </mat-chip-set>
  </div>
</div>
