import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isEquals',
})
export class IsEqualsPipe implements PipeTransform {
  constructor() {
  }

  transform(aud: any, name: string, results?: boolean): boolean {
    const currentValue = aud.current?.hasOwnProperty(name) ? aud.current[name] : '';
    const oldValue = aud.old?.hasOwnProperty(name) ? aud.old[name] : '';
    return currentValue === oldValue;
  }
}
