<mat-toolbar>
  <button mat-icon-button (click)="matBottomSheet.dismiss()">
    <mat-icon>arrow_back</mat-icon>
  </button>
</mat-toolbar>
<mat-card>
  <mat-card-content>
    <mat-form-field class="example-full-width">
      <mat-label>O que seria uma boa resposta?</mat-label>
      <textarea rows="8" [(ngModel)]="answer" matInput
                placeholder="Escreva aqui..."></textarea>
    </mat-form-field>
    <button class="maria-button-raised w-100" mat-raised-button (click)="matBottomSheet.dismiss(answer)">
      <span>Enviar</span>
    </button>
  </mat-card-content>
</mat-card>
