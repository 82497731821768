import { Component, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheet } from '@angular/material/bottom-sheet';
import { MessageChat } from '../maria-chat-dialog/maria-chat-dialog.component';

@Component({
  selector: 'maria-chat-rating-bad-dialog',
  templateUrl: './maria-chat-rating-bad-dialog.component.html',
  styleUrls: ['./maria-chat-rating-bad-dialog.component.scss']
})
export class MariaChatRatingBadDialogComponent {
  answer: string;

  constructor(
    public matBottomSheet: MatBottomSheet,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: MessageChat,
  ) {
    this.answer = data?.adminCorrectedContent?.replace(/<br\s*\/?>/gi, '\n');
  }
}
