import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UrlService {
  organizationsList(): string {
    return '/organizacoes';
  }

  organizationsBase(id: unknown): string {
    return [this.organizationsList(), id].join('/');
  }

  personList(): string {
    return '/pessoas';
  }

  personDetailsBase(id: unknown) {
    return ['pessoas', id].join('/');
  }

  personDetailsRisk(personId: unknown, id?: unknown) {
    let url;
    if (id) {
      url = [this.personDetailsBase(personId), 'riscos', id];
    } else {
      url = [this.personDetailsBase(personId), 'riscos'];
    }
    return url.join('/');
  }

  examsParses() {
    return [this.personList(), 'exames-pendentes'].join('/');
  }

  examsParsesDetail(id: unknown) {
    return [this.examsParses(), 'detalhes', id].join('/');
  }

  organizationsFeatureFlags(): string {
    return ['/organizacoes/feature-flags/detalhes'].join('/');
  }
  organizationsFeatureFlagDetail(id: unknown): string {
    return [this.organizationsFeatureFlags(), id].join('/');
  }


  personDetailsForms(id: unknown, formSubmissionId?) {
    let url;
    if (formSubmissionId) {
      url = [this.personDetailsBase(id), 'questionario', formSubmissionId];
    } else {
      url = [this.personDetailsBase(id), 'questionario'];
    }
    return url.join('/');
  }

}
