import { Injectable } from '@angular/core';
import { RouterParamsService } from '../../shared/services/router-params.service';
import { BaseEntityService } from '../entity-management/services/base/base-entity.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { PersonMessage, PersonMessageEvent } from '../models/person-messages';
import { filter, map, switchMap, takeWhile } from 'rxjs/operators';
import { JwtAuthService } from '../../shared/services/auth/jwt-auth.service';
import { BehaviorSubject, combineLatest, interval, Observable, of } from 'rxjs';
import { PreviousMedication } from '../models/previous-medication';

@Injectable({
  providedIn: 'root',
})
export class PersonMessagesEntityService extends BaseEntityService<PersonMessage> {
  private readonly POLLING_INTERVAL = 3000;

  appName: PersonMessage['appName'] = 'PATIENT';
  private personMessageSubject = new BehaviorSubject<PreviousMedication[]>([]);

  constructor(
    private jwtAuthService: JwtAuthService,
    httpClient: HttpClient,
    routerParamsService: RouterParamsService,
  ) {
    super(
      httpClient,
      environment.apiUrl, { singular: 'personMessage', plural: 'personMessages', url: 'person-messages' },
      routerParamsService
    );
    this.nameId = 'personMessageId';
  }

  public getMessages(): Observable<PersonMessage[]> {
    return this.personMessageSubject.asObservable();
  }

  private get getDefaultParameters(): PersonMessage {
    const { id } = this.jwtAuthService.getUser();
    return {
      appName: this.appName,
      person: { id },
      source: 'USER',
    };
  }

  public getMessagesApi(params = {}): Observable<PersonMessage[]> {
    return this.getWithQuery({
      limit: 50,
      appName: this.appName,
      ...params
    });
  }

  public sendMessage(personMessage: PersonMessage): Observable<PersonMessage> {
    return this.add({
      ...personMessage,
      ...this.getDefaultParameters,
    }).pipe(
      switchMap(() => this.pollForResponse())
    );
  }

  public ratingMessage(personMessage: PersonMessage, params: any = {}): Observable<PersonMessage[]> {
    return this.update(personMessage).pipe(
      switchMap(() => this.getMessagesApi(params))
    );
  }

  private pollForResponse(): Observable<PersonMessage | null> {
    return interval(this.POLLING_INTERVAL).pipe(
      switchMap(() => combineLatest([this.getMessagesApi(), this.checkJobEvents()])),
      map(([messages, hasPendingJob]) => {
        const [message] = messages;
        if (this.checkPollPersonMessageCreated(message, hasPendingJob)) {
          return message;
        }
        return null;
      }),
      takeWhile(response => response === null, true),
    );
  }

  getMessagesApiFromSystem(): Observable<PersonMessage | null> {
    return this.getMessagesApi().pipe(
      switchMap(personMessages => {
        const [personMessage] = personMessages || [];
        if (personMessage?.triggerEvent?.type !== 'PERSON_MESSAGE_CREATED') {
          return of(personMessage);
        }
        return this.pollForResponse().pipe(
          filter(response => {
            return response.triggerEvent.type !== 'PERSON_MESSAGE_CREATED';
          }),
        );
      }),
    );

  }

  checkPollPersonMessageCreated(sentMessage: PersonMessage, hasPendingJob: any[]): boolean {
    return sentMessage.source === 'SYSTEM' && hasPendingJob.length === 0;
  }

  public setContext(personMessageAppContext: PersonMessage['appContext']): Observable<PersonMessage> {
    return this.add({
      appContext: personMessageAppContext,
      contentBlocks: [
        {}
      ],
      ...this.getDefaultParameters,
    });
  }

  public sendPersonMessageEvents(type: PersonMessageEvent['type'], params?: Record<string, any>): Observable<PersonMessageEvent> {
    const { id } = this.jwtAuthService.getUser();
    return this.httpClient.post<PersonMessageEvent>(`${ environment.apiUrl }/person-message-events`, {
      personMessageEvent: {
        type,
        params,
        person: {
          id
        }
      }
    });
  }

  public checkJobEvents() {
    const { id: personIds } = this.jwtAuthService.getUser();
    const params = { types: 'PERSON_MESSAGE_EVENT_PROCESS', personIds, status: 'PENDING' };
    return this.httpClient.get<any[]>(`${ environment.apiUrl }/jobs`, { params }).pipe(
      map(response => {
        return response['jobs'].filter((job: { type: string, status: string; }) => {
          return job.type === 'PERSON_MESSAGE_EVENT_PROCESS' && job.status === 'PENDING';
        });
      }),
    );
  }
}
