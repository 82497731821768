import { Pipe, PipeTransform } from '@angular/core';
import { UtilsService } from '../services/utils.service';

@Pipe({
  name: 'document',
})
export class DocumentPipe implements PipeTransform {
  constructor(private utilsService: UtilsService) {
  }

  transform(value: string, type = 'CNPJ'): string {
    const onlyNumbers = value?.replace(/\D/g, '');
    if (type === 'CPF') {
      return onlyNumbers?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    }

    if (type === 'CNPJ') {
      return onlyNumbers?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    }


    return this.utilsService.maskCpfCnpj(value);
  }
}
