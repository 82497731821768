import { Injectable } from '@angular/core';
import { ButtonOptions, ContentBlock, PersonMessage } from '../../../state/models/person-messages';
import { MessageByType } from '../dialog/maria-chat-dialog/maria-chat-dialog.component';
import { marked } from 'marked';
import { LocalStoreService } from '../../services/local-store.service';

@Injectable({
  providedIn: 'root'
})
export class MariaUtilsService {

  userFirstChatOpen = 'USER_FIRST_CHAT_OPEN';

  constructor(
    private localStorageService: LocalStoreService,
  ) {
  }

  public get hasUserFirstChatOpen(): boolean {
    return !!this.localStorageService.getItem(this.userFirstChatOpen);
  }

  public setUserFirstChatOpen() {
    this.localStorageService.setItem(this.userFirstChatOpen, true);
  }


  getMessageByType(contentBlocks: ContentBlock[], source: PersonMessage['source']): GetMessageByType {
    let messages: MessageByType[] = [];
    let buttonOptionsArr = [];

    contentBlocks.forEach(contentBlock => {
      if (contentBlock.type === 'PLAIN_TEXT') {
        messages.push({ type: 'text', message: contentBlock.plainText.plainText });
      }
      if (contentBlock.type === 'MARKDOWN') {
        messages.push({ type: 'text', message: marked(contentBlock.markdown.markdown) });
      }
      if (contentBlock.type === 'GO_TO') {
        messages.push({ type: 'button', button: contentBlock.goTo.destination });
      }
      if (contentBlock.type === 'BUTTON_OPTIONS') {
        const { buttonOptions = [] } = contentBlock;
        if (source === 'SYSTEM') {
          buttonOptionsArr = buttonOptions;
        } else if (source === 'USER') {
          const [buttonOption] = buttonOptions;
          const { plainText } = buttonOption;
          messages.push({ type: 'text', message: plainText });
        }
      }
    });
    return { messages, buttonOptions: buttonOptionsArr };
  }

}

export interface GetMessageByType {
  messages: MessageByType[],
  buttonOptions: ButtonOptions[]
}
