import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { FormAnswer } from '../../../state/models/form.model';

@Injectable({
  providedIn: 'root',
})
export class PersonFormService {
  constructor() {}

  getFormAnswersFormat(formAnswer: FormAnswer) {
    if (!!formAnswer?.formQuestion) {
      const { formQuestion, value, formAnswerChoices } = formAnswer;
      const { type } = formQuestion;
      if (type === 'TEXT' || type === 'NUMBER') {
        return value;
      } else if (type === 'CHOICE') {
        const [formAnswerChoice] = formAnswerChoices || [];
        return formAnswerChoice?.value;
      } else if (type === 'CHOICES') {
        return formAnswerChoices?.map(fac => fac.value)?.join(', ');
      }
    }
    return 'Não respondido';
  }
}
