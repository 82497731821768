import { Injectable, signal, WritableSignal } from '@angular/core';
import { RouterParamsService } from '../../shared/services/router-params.service';
import { BehaviorSubject, distinct, Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { BaseEntityService } from '../entity-management/services/base/base-entity.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Person, PersonRegistration } from '../models/person';
import { CrmPersons } from '../models/crm-persons';
import { DateService } from '../../shared/services/date.service';
import { AuthStatements } from '../models/auth-statements';
import { SubscriptionEntityService } from './subscription-entity.service';
import { User } from '../models/user.model';
import { FormAnswersEntityService } from './form-answers-entity.service';

@Injectable({
  providedIn: 'root'
})
export class PersonEntityService extends BaseEntityService<Person> {
  public lastPersonWithAge: Person;
  private routerParamsLocalService: RouterParamsService;
  private authstatements: WritableSignal<AuthStatements[]> = signal([]);

  lastPersonWithAgeSubject = new BehaviorSubject<Person>(undefined);
  lastCrmPersonSubject = new BehaviorSubject<CrmPersons>(undefined);

  constructor(
    private formAnswersEntityService: FormAnswersEntityService,
    private subscriptionEntityService: SubscriptionEntityService,
    private dateService: DateService,
    httpClient: HttpClient,
    routerParamsService: RouterParamsService,
  ) {
    super(
      httpClient,
      environment.apiUrl, { singular: 'person', plural: 'persons' },
      routerParamsService
    );
    this.nameId = 'personId';
    this.routerParamsLocalService = routerParamsService;
  }

  getLastPersonWithAge(): Observable<Person> {
    return this.lastPersonWithAgeSubject.asObservable();
  }

  getLastCrmPerson(): Observable<CrmPersons> {
    return this.lastCrmPersonSubject.asObservable();
  }

  getCurrentFromApiCustom(id?: number): Observable<Person> {
    return this.routerParamsLocalService.params.pipe(
      filter(params => {
        return !!params[this.nameId] || !!id;
      }),
      map(params => params[this.nameId]),
      switchMap(ids => {
        return this.getWithQuery({ ids: id || ids }).pipe(
          map(response => {
            const [person] = response;
            return person;
          }),
        );
      }),
    );
  }

  getSelf(): Observable<User> {
    return this.httpClient
      .get(`${ this.entityUrl }/self`)
      .pipe(map((response: any) => response[this.singularEntityName]));
  }

  generateSelfieLink(personId: number): Observable<string> {
    return this.httpClient
      .post(`${ this.entityUrl }/${ personId }/generate-selfie-url`, {})
      .pipe(map((response: any) => response[this.singularEntityName]?.selfieUrl));
  }

  crmPersons(personIds: number): Observable<CrmPersons> {
    return this.httpClient
      .get(`${ environment.apiUrl }/crm-persons`, { params: { personIds } })
      .pipe(
        map((response: any) => {
          const [crmPerson] = response?.crmPersons || [];
          this.lastCrmPersonSubject.next(crmPerson);
          return crmPerson;
        })
      );
  }

  authInvitesAcceptAll(): Observable<string> {
    return this.httpClient
      .post(`${ environment.apiUrl }/auth-invites/accept-all`, {})
      .pipe(
        map(() => {
          return 'ok';
        })
      );
  }

  personRegistrations(document: string): Observable<PersonRegistration> {
    return this.httpClient
      .get(`${ environment.apiUrl }/person-registrations/`, { params: { document } })
      .pipe(
        map((response: any) => {
          return response?.personRegistration;
        })
      );
  }

  public getCurrentWithAge(): Observable<Person> {
    return this.getCurrentFromApi().pipe(
      switchMap(person => {
        return this.subscriptionEntityService.getWithQuery(
          { active: 'true', personIds: person.id.toString() },
        ).pipe(
          map(subscriptions => {
            const organizationNames = subscriptions
              .map(s => `<br/> - ${ s.organization.name } # ${ s.organizationProvidedId || '' }`)
              .join('');

            let { birthdate: age } = person.personProperties;
            age = this.dateService.diffByYears(age) as any;
            this.lastPersonWithAge = {
              ...person,
              organizationNames,
              age,
            };
            this.lastPersonWithAgeSubject.next(this.lastPersonWithAge);
            return this.lastPersonWithAge;
          }),
        );
      }),
    );
  }

  public getCurrentUndistict(): Observable<Person> {
    return this.routerParamsLocalService.params.pipe(
      filter(params => !!params[this.singularEntityName]),
      map((params: any) => params[this.singularEntityName]),
      switchMap(id => this.getEntityById(id)),
    );
  }

  get authStatementDoctor(): AuthStatements[] {
    return this.authstatements().filter(as => as.role.name === 'DOCTOR');
  }

  get authStatementsList(): AuthStatements[] {
    return this.authstatements();
  }

  public setAuthStatement(response: AuthStatements[]) {
    this.authstatements.set(response);
  }

  getPageForms(formSubmissionId?: number): Observable<unknown> {
    return this.getCurrent().pipe(
      distinct(person => person.id),
      switchMap(person => {
        return this.formAnswersEntityService.getQuestionsWithAnswers({
          personIds: person.id.toString(),
          limit: '200',
          formSubmissionId,
        });
      }),
    );
  }
}
